// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-articles-colour-technology-and-heritage-jsx": () => import("./../../../src/pages/articles/colour-technology-and-heritage.jsx" /* webpackChunkName: "component---src-pages-articles-colour-technology-and-heritage-jsx" */),
  "component---src-pages-articles-do-fitted-wardrobes-add-value-to-house-jsx": () => import("./../../../src/pages/articles/do-fitted-wardrobes-add-value-to-house.jsx" /* webpackChunkName: "component---src-pages-articles-do-fitted-wardrobes-add-value-to-house-jsx" */),
  "component---src-pages-articles-have-you-seen-the-light-jsx": () => import("./../../../src/pages/articles/have-you-seen-the-light.jsx" /* webpackChunkName: "component---src-pages-articles-have-you-seen-the-light-jsx" */),
  "component---src-pages-articles-index-jsx": () => import("./../../../src/pages/articles/index.jsx" /* webpackChunkName: "component---src-pages-articles-index-jsx" */),
  "component---src-pages-articles-make-the-most-of-your-space-jsx": () => import("./../../../src/pages/articles/make-the-most-of-your-space.jsx" /* webpackChunkName: "component---src-pages-articles-make-the-most-of-your-space-jsx" */),
  "component---src-pages-articles-staying-in-neutral-jsx": () => import("./../../../src/pages/articles/staying-in-neutral.jsx" /* webpackChunkName: "component---src-pages-articles-staying-in-neutral-jsx" */),
  "component---src-pages-articles-the-finishing-touches-jsx": () => import("./../../../src/pages/articles/the-finishing-touches.jsx" /* webpackChunkName: "component---src-pages-articles-the-finishing-touches-jsx" */),
  "component---src-pages-articles-working-from-home-jsx": () => import("./../../../src/pages/articles/working-from-home.jsx" /* webpackChunkName: "component---src-pages-articles-working-from-home-jsx" */),
  "component---src-pages-contact-ask-a-question-jsx": () => import("./../../../src/pages/contact/ask-a-question.jsx" /* webpackChunkName: "component---src-pages-contact-ask-a-question-jsx" */),
  "component---src-pages-contact-design-visit-jsx": () => import("./../../../src/pages/contact/design-visit.jsx" /* webpackChunkName: "component---src-pages-contact-design-visit-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-contact-request-brochure-jsx": () => import("./../../../src/pages/contact/request-brochure.jsx" /* webpackChunkName: "component---src-pages-contact-request-brochure-jsx" */),
  "component---src-pages-contact-virtual-design-visit-jsx": () => import("./../../../src/pages/contact/virtual-design-visit.jsx" /* webpackChunkName: "component---src-pages-contact-virtual-design-visit-jsx" */),
  "component---src-pages-coronavirus-covid-19-jsx": () => import("./../../../src/pages/coronavirus-covid-19.jsx" /* webpackChunkName: "component---src-pages-coronavirus-covid-19-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portfolio-angled-wardrobe-jsx": () => import("./../../../src/pages/portfolio/angled-wardrobe.jsx" /* webpackChunkName: "component---src-pages-portfolio-angled-wardrobe-jsx" */),
  "component---src-pages-portfolio-bedroom-jsx": () => import("./../../../src/pages/portfolio/bedroom.jsx" /* webpackChunkName: "component---src-pages-portfolio-bedroom-jsx" */),
  "component---src-pages-portfolio-bespoke-storage-solutions-jsx": () => import("./../../../src/pages/portfolio/bespoke-storage-solutions.jsx" /* webpackChunkName: "component---src-pages-portfolio-bespoke-storage-solutions-jsx" */),
  "component---src-pages-portfolio-fitted-wardrobe-jsx": () => import("./../../../src/pages/portfolio/fitted-wardrobe.jsx" /* webpackChunkName: "component---src-pages-portfolio-fitted-wardrobe-jsx" */),
  "component---src-pages-portfolio-index-jsx": () => import("./../../../src/pages/portfolio/index.jsx" /* webpackChunkName: "component---src-pages-portfolio-index-jsx" */),
  "component---src-pages-portfolio-kitchen-jsx": () => import("./../../../src/pages/portfolio/kitchen.jsx" /* webpackChunkName: "component---src-pages-portfolio-kitchen-jsx" */),
  "component---src-pages-portfolio-lounge-media-unit-jsx": () => import("./../../../src/pages/portfolio/lounge-media-unit.jsx" /* webpackChunkName: "component---src-pages-portfolio-lounge-media-unit-jsx" */),
  "component---src-pages-portfolio-office-library-jsx": () => import("./../../../src/pages/portfolio/office-library.jsx" /* webpackChunkName: "component---src-pages-portfolio-office-library-jsx" */),
  "component---src-pages-portfolio-under-stairs-storage-jsx": () => import("./../../../src/pages/portfolio/under-stairs-storage.jsx" /* webpackChunkName: "component---src-pages-portfolio-under-stairs-storage-jsx" */),
  "component---src-pages-portfolio-walk-in-wardrobe-jsx": () => import("./../../../src/pages/portfolio/walk-in-wardrobe.jsx" /* webpackChunkName: "component---src-pages-portfolio-walk-in-wardrobe-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-process-jsx": () => import("./../../../src/pages/process.jsx" /* webpackChunkName: "component---src-pages-process-jsx" */)
}

